<template>
  <div class="w-100">
    <b-row>
      <b-card no-body>
        <b-overlay :show="loading">
          <b-card-body>
            <b-card-title>
              <b-row>
                <b-col>
                  <div>Banner</div>
                </b-col>
                <b-col>
                  <div align="right" v-if="allowCreate()">
                    <b-button
                      variant="outline-primary"
                      v-b-modal.modal-lg
                      @click="ModalShow()"
                      class="btn-icon"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />Tambah
                      Banner</b-button
                    >
                  </div>
                  <!-- size sm,lg -->
                  <b-modal
                    v-model="Modal"
                    id="modal-tambah"
                    centered
                    size="lg"
                    :title="titleModal"
                  >
                    <b-card-text>
                      <b-form>
                        <b-row>
                          <b-col md="12">
                            <b-form-group
                              label="Thumbnail (Ukuran maks: 1440x424px untuk slide)"
                              label-for="Thumbnail"
                            >
                              <b-form-file
                                id="Thumbnail"
                                v-model="form.image"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                no-drop
                                accept="image/*"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            class="mb-1"
                            md="12"
                            v-if="form.image2 != null"
                          >
                            <strong>Thumbnail : </strong><br />
                            <b-img
                              :src="apiFile + form.image2"
                              fluid
                              :alt="form.image"
                            />
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                              label="Link (Opsional)"
                              label-for="Link"
                            >
                              <b-form-input
                                id="Link"
                                v-model="form.link"
                                placeholder="Link"
                                type="url"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group label="Order" label-for="Order">
                              <b-form-input
                                id="Order"
                                v-model="form.order"
                                placeholder="Order"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group
                              label="Aktif/Nonaktif (Opsional)"
                              label-for="Aktif/Nonaktif"
                            >
                              <b-form-checkbox
                                switch
                                inline
                                id="Active"
                                v-model="form.active"
                                placeholder="Active"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>

                    <template #modal-footer>
                      <div class="w-100">
                        <p class="float-left mb-0"></p>

                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          variant="outline-success"
                          class="mr-1 btn-icon"
                          @click.prevent="Tambah"
                        >
                          <feather-icon icon="SaveIcon" class="mr-25" />{{
                            label || "Tambah"
                          }}
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-primary"
                          class="float-right btn-icon"
                          @click="Modal = false"
                        >
                          <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                        </b-button>
                      </div>
                    </template>
                  </b-modal>

                  <b-modal
                    v-model="ModalUploadThumbnail"
                    id="modal-upload"
                    centered
                    size="lg"
                    :title="titleModal"
                  >
                    <b-card-text>
                      <b-form>
                        <b-row>
                          <b-col md="12">
                            <b-form-group
                              label="Thumbnail (Ukuran maks: 1440x424px untuk slide)"
                              label-for="Thumbnail"
                            >
                              <b-form-file
                                id="Thumbnail"
                                v-model="form.image"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                no-drop
                                accept="image/*"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>

                    <template #modal-footer>
                      <div class="w-100">
                        <p class="float-left mb-0"></p>

                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          variant="outline-success"
                          class="mr-1 btn-icon"
                          @click.prevent="UploadThumbnail"
                        >
                          <feather-icon icon="SaveIcon" class="mr-25" />{{
                            label || "Tambah"
                          }}
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-primary"
                          class="float-right btn-icon"
                          @click="
                            ModalUploadThumbnail = false;
                            getDataById();
                          "
                        >
                          <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                        </b-button>
                      </div>
                    </template>
                  </b-modal>
                </b-col></b-row
              >
            </b-card-title>
            <b-card-text class="blog-caption-truncate">
              <b-row>
                <b-col md="3" sm="4" class="my-1">
                  <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50"
                      >Per page</label
                    >
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      class="w-50"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4" sm="8" class="my-1">
                  <b-form-group
                    label="Sort"
                    label-cols-sm="2"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-select
                        id="sortBySelect"
                        v-model="sortBy"
                        :options="sortOptions"
                        class="w-50"
                      >
                        <template v-slot:first>
                          <option value="">-- none --</option>
                        </template>
                      </b-form-select>
                      <b-form-select
                        v-model="sortDesc"
                        size="sm"
                        :disabled="!sortBy"
                        class="w-30"
                      >
                        <option :value="false">Asc</option>
                        <option :value="true">Desc</option>
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="5" class="my-1">
                  <b-form-group label-for="filterInput" class="mb-0">
                    <b-input-group size="sm">
                      <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Search......"
                      />
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                          Clear
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

                <b-col cols="12">
                  <b-table
                    striped
                    small
                    hover
                    responsive
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    :fields="fields"
                    :items="bannerData"
                  >
                    <!-- A virtual column -->
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                    <template #cell(active)="data">
                      <b-badge variant="success" v-if="data.item.active == 1">
                        Aktif
                      </b-badge>
                      <b-badge variant="danger" v-else="data.item.active == 0">
                        Tidak Aktif
                      </b-badge>
                    </template>

                    <!-- A custom formatted column -->
                    <template #cell(action)="row">
                      <div v-if="bannerData.length == 1">
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                          v-if="allowUpdate() || allowDelete()"
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25"
                              style="height: 100px"
                            />
                          </template>
                          <b-dropdown-item @click="ModalUpload(row.item)">
                            <feather-icon
                              icon="UploadCloudIcon"
                              class="mr-50"
                            />
                            <span>Upload Thumbnail</span>
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="allowUpdate()"
                            @click="ModalUbah(row.item)"
                          >
                            <feather-icon icon="Edit2Icon" class="mr-50" />
                            <span>Edit</span>
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="allowDelete()"
                            @click="ModalHapus(row.item)"
                          >
                            <feather-icon icon="TrashIcon" class="mr-50" />
                            <span>Hapus</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                      <div v-if="bannerData.length != 1">
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                          v-if="allowUpdate() || allowDelete()"
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25"
                            />
                          </template>
                          <b-dropdown-item @click="ModalUpload(row.item)">
                            <feather-icon
                              icon="UploadCloudIcon"
                              class="mr-50"
                            />
                            <span>Upload Thumbnail</span>
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="allowUpdate()"
                            @click="ModalUbah(row.item)"
                          >
                            <feather-icon icon="Edit2Icon" class="mr-50" />
                            <span>Edit</span>
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="allowDelete()"
                            @click="ModalHapus(row.item)"
                          >
                            <feather-icon icon="TrashIcon" class="mr-50" />
                            <span>Hapus</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </template>
                    <!-- Optional default data cell scoped slot -->
                    <template #cell(show_details)="row">
                      <b-form-checkbox
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con"
                        @change="row.toggleDetails"
                      >
                        <span class="vs-checkbox">
                          <span class="vs-checkbox--check">
                            <i class="vs-icon feather icon-check" />
                          </span>
                        </span>
                        <span class="vs-label">{{
                          row.detailsShowing ? "Hide" : "Show"
                        }}</span>
                      </b-form-checkbox>
                    </template>
                    <template #row-details="row">
                      <b-card>
                        <b-row class="mb-2">
                          <b-col class="mb-1">
                            <strong>Thumbnail : </strong><br />
                            <b-img
                              :src="apiFile + row.item.image"
                              style="max-width: 500px"
                              :alt="row.item.image"
                            />
                          </b-col>
                        </b-row>
                        <!-- <b-row>
                      <b-col class="mb-1">
                        <strong>Konten : </strong><br />
                        <quill-editor v-model="row.item.caption" />
                      </b-col>
                    </b-row> -->

                        <b-button
                          size="sm"
                          variant="outline-secondary"
                          @click="row.toggleDetails"
                        >
                          Hide Details
                        </b-button>
                      </b-card>
                    </template>
                  </b-table>
                  <!-- <b-pagination
          v-if="record !== 0"
          :total="Math.ceil((total * 1) / itemsPerPage)"
          v-model="currentPage"
          @change="staf()"
        /> -->
                  <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
          :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="center"
                    size="sm"
                    class="my-0"
                  />
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
        </b-overlay>
      </b-card>
    </b-row>
    <b-row> <card-popup /> </b-row>
  </div>
</template>

<script>
import {
  BDropdownItem,
  BDropdown,
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BImg,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"; // eslint-disable-next-line
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import CardPopup from "./popup/Index.vue";

export default {
  components: {
    CardPopup,
    BDropdownItem,
    BDropdown,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BImg,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    ToastificationContent,
    quillEditor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      bannerData: [],
      form: {
        id: null,
        active: false,
        link: null,
        order: 1,
        type: "slide",
        image: null,
        caption: null,
      },
      //select
      optTipe: [
        { value: "slide", text: "Slide" },
        { value: "popup", text: "Pop Up" },
      ],
      optcategory_id: [],
      //Modal
      titleModal: null,
      Modal: false,
      ModalUploadThumbnail: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "show_details", label: "#" },
        { key: "index", label: "No" },
        { key: "link", label: "Link", sortable: true },
        { key: "order", label: "order", sortable: true },
        // { key: "type", label: "Tipe", sortable: true },
        { key: "active", label: "Aktif", sortable: true },
        // { key: "caption", label: "Konten", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "link", value: "Link" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Kategori",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {},
  computed: {
    isLinkValid() {
      if (this.form.link != "" && this.form.link) {
        let url = this.form.link;
        try {
          url = new URL(url);
        } catch (_) {
          return false;
        }

        return true;
      }

      return true;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalUpload(item) {
      this.form.id = item.id;
      this.form.active = item.active == 1 ? true : false;
      this.form.link = item.link;
      this.form.order = item.order;
      this.form.type = item.type;
      this.form.caption = item.caption;
      this.form.image = null;
      this.form.image2 = item.image;
      this.activeAction = "upload";
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    UploadThumbnail() {
      const isImageEmpty = this.form.image == "" || this.form.image == null
      if(isImageEmpty) {
        this.displayError({
          message: 'Harap Upload Gambar Banner!'
        })
        return false
      }

      const fd = new FormData();
      if (
        this.id != null ||
        this.form.id != null ||
        this.form.image == null ||
        this.form.image == "" ||
        this.form.caption == null ||
        this.form.caption == "" ||
        this.form.type == null ||
        this.form.type == "" ||
        this.form.active == null ||
        this.form.active == "" ||
        this.form.order == null ||
        this.form.order == ""
      ) {
        fd.append("id", this.form.id);
        if (this.form.image == null) {
          fd.append("link", this.form.link);
          fd.append("caption", this.form.caption);
          fd.append("type", this.form.type);
          fd.append("order", this.form.order);
          fd.append("active", this.form.active == true ? 1 : 0);
        } else {
          fd.append("link", this.form.link);
          fd.append("caption", this.form.caption);
          fd.append("type", this.form.type);
          fd.append("order", this.form.order);
          fd.append("active", this.form.active == true ? 1 : 0);
          fd.append("image", this.form.image);
        }
      } else {
        // fd.append("image", this.form.image);
        fd.append("link", this.form.link);
        fd.append("caption", this.form.caption);
        fd.append("type", this.form.type);
        fd.append("order", this.form.order);
        fd.append("active", this.form.active == true ? 1 : 0);
      }

      this.$store
        .dispatch("banner/createUpdateDelete", fd)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }

          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Thumbnail berhasil di" + this.activeAction,
          });
          this.ModalUploadThumbnail = false;
          this.form = {
            id: null,
            active: 0,
            link: null,
            order: 1,
            type: "slide",
            image: null,
            caption: null,
          };
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    ModalShow() {
      this.id = null;
      this.form.image2 = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Banner";
      this.Modal = true;
    },
    ModalUbah(item) {
      this.form.id = item.id;
      this.form.active = item.active == 1 ? true : false;
      this.form.link = item.link;
      this.form.order = item.order;
      this.form.type = item.type;
      this.form.caption = item.caption;
      this.form.image = null;
      this.form.image2 = item.image;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Banner";
      this.Modal = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("banner/createUpdateDelete", data)
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.$swal({
                icon: "success",
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagal2() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, image harus diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (!this.isLinkValid) {
        this.displayError({
          message: "Link tidak valid",
        });

        return false;
      }
      if (
        (this.id != null && this.form.image == null) ||
        (this.id != null && this.form.image == "")
      ) {
        this.pesanGagal2();
        return false;
      }
      if (
        this.form.type == null ||
        this.form.type == "" ||
        this.form.order == null ||
        this.form.order == ""
      ) {
        this.pesanGagal();
        return false;
      }
      // const payload = this.form;
      const fd = new FormData();
      if (this.id != null || this.form.id != null) {
        fd.append("id", this.form.id);
      }
      if (this.form.link && this.form.link != "") {
        fd.append("link", this.form.link);
      }
      if (this.form.caption && this.form.caption != "") {
        fd.append("caption", this.form.caption);
      }

      fd.append("type", this.form.type);
      fd.append("order", this.form.order);
      fd.append("active", this.form.active == true ? 1 : 0);
      if (this.form.image) {
        fd.append("image", this.form.image);
      }

      this.$store
        .dispatch("banner/createUpdateDelete", fd)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.Modal = false;
          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Banner/Pop Up berhasil di" + this.activeAction,
          });
          this.form = {
            id: null,
            active: 0,
            link: null,
            order: 1,
            type: "slide",
            image: null,
            caption: null,
          };
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getData() {
      this.loading = true;
      const params = {
        type: "slide",
      };
      this.$store
        .dispatch("banner/index", params)
        .then((response) => {
          this.loading = false;
          let bannerData = response.data.data;
          this.bannerData = bannerData;
          this.totalRows = this.bannerData.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
  },
  created() {},
  async mounted() {
    this.getData();
  },
};
</script>

<style></style>
